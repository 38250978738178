/**
 * check if value passed is Indian pin code or not
 *
 * @param {string} pincodeStr -  pincode value
 * @returns {boolean} returns true if pincode value is Indian pincode, false otherwise
 */
const isIndPincode = (pincodeStr = "") => {
  const pinPattern = /^[1-9][0-9]{5}$/;
  return pinPattern.test(pincodeStr);
};

export default isIndPincode;
