import React from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import style from "../style/tooltip.module.scss";

/**
 * This functional component represents the custom message tooltip for the desktop .
 *
 * @param {object} root0 it includes object from parent component
 * @param {object} root0.color color of the tooltip .
 * @param {object} root0.value  message of the tooltip.
 * @param {string} root0.classProps custom class name for tooltip .
 * @param {Function} root0.handleClickAway function that triggers on click away.
 * @param {Function} root0.large To show large tooltip.
 * @param {string} root0.position position
 * @param {boolean} root0.deliveryInputPosition .
 * @returns {React.Fragment} - React component
 */
function TooltipCustom({ color, value, classProps, handleClickAway, large, deliveryInputPosition, position }) {
  const classes = `${style.tooltip} ${large ? style["animate-tooltip-large"] : ""} ${
    color === "red" ? style.red : style.black
  } ${classProps === "set-top-position" ? style.setTopPosition : classProps}
  ${deliveryInputPosition ? style.setPosition : ""} ${style[position]}`;
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          handleClickAway();
        }}
      >
        <span className={classes}>
          <span>{value}</span>
        </span>
      </ClickAwayListener>
    </>
  );
}

TooltipCustom.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  classProps: PropTypes.string,
  handleClickAway: PropTypes.func,
  large: PropTypes.bool,
  deliveryInputPosition: PropTypes.bool,
  position: PropTypes.string,
};

TooltipCustom.defaultProps = {
  classProps: "",
  handleClickAway: () => {},
  large: false,
  deliveryInputPosition: false,
  position: "right",
};
export default TooltipCustom;
